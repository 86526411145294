var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", function () {
        return [
          _c("SecondaryButton", {
            class: [_vm.margin],
            attrs: { text: _vm.text, width: "w-22" },
            on: { click: _vm.addCard },
          }),
        ]
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCard,
                expression: "showCard",
              },
            ],
            staticClass: "h-screen w-screen bg-white z-20",
            staticStyle: { position: "fixed", top: "0", left: "0" },
          },
          [
            _c(
              "iframe",
              {
                ref: "iframeRef",
                staticStyle: {
                  left: "0",
                  "b right": "0",
                  width: "100%",
                  height: "100%",
                  border: "none",
                  margin: "0",
                  padding: "0",
                  "z-index": "99",
                  overflow: "hidden",
                },
                attrs: {
                  src: _vm.source,
                  frameborder: "0",
                  allowfullscreen: "",
                  sandbox:
                    "allow-same-origin allow-scripts allow-popups allow-forms allow-modals",
                },
                on: { load: _vm.loadIframe },
              },
              [_vm._v(" Your browser doesn't support iframes ")]
            ),
          ]
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.showPaymentOptionsModal,
            title: "Oh, adding card was unsuccessful.",
          },
          on: {
            close: function ($event) {
              _vm.showPaymentOptionsModal = false
            },
          },
        },
        [
          _c("CardProviders", {
            attrs: { loading: _vm.loadProviders, options: _vm.options },
            on: {
              selected: _vm.addCard,
              cancel: function ($event) {
                _vm.showPaymentOptionsModal = false
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }