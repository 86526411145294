<template>
  <div>
    <slot>
      <SecondaryButton :class="[margin]" :text="text" @click="addCard" width="w-22" />
    </slot>
    <transition name="fade">
      <div
        v-show="showCard"
        style="position: fixed; top: 0; left: 0"
        class="h-screen w-screen bg-white z-20"
      >
        <iframe
          :src="source"
          style="frameBorder=0; left:0; b right:0; width:100%; height:100%; border:none; margin:0; padding:0; z-index:99; overflow: hidden"
          frameborder="0"
          ref="iframeRef"
          allowfullscreen
          @load="loadIframe"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
        >
          Your browser doesn't support iframes
        </iframe>
      </div>
    </transition>

    <Modal
      :display="showPaymentOptionsModal"
      @close="showPaymentOptionsModal = false"
      title="Oh, adding card was unsuccessful."
    >
      <CardProviders :loading="loadProviders" @selected="addCard" :options="options" @cancel="showPaymentOptionsModal = false" />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { VERIFY_CARD } from "@/utils/api/verification";
import { SecondaryButton } from "@/UI/Button";
import { ADD_CARD } from "@/utils/api/userInfo";
import { GET_CARD_PROCESSERS } from "@/utils/api/userInfo.js";
import { Modal } from "@/UI/Modals";

export default {
  components: {
    SecondaryButton,
    Modal,
    CardProviders: () => import("./CardProviders.vue")
  },

  props: {
    text: {
      type: String,
      default: "Add card",
    },
    margin: {
      type: String,
      default: "",
    },
    startAddCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showCard: false,
    initiateCard: "",
    transactionId: "",
    source: "",
    options: [],
    showPaymentOptionsModal: false,
    loadProviders: false
  }),

  computed: {
    ...mapState({
      walletId: (state) => state?.auth?.user?.instaPayWalletId,
      creditQualification: (state) =>
        state?.userInfo?.creditQualification?.qualification,
    }),
  },

  watch: {
    startAddCard(value) {
      if(value) {
        this.addCard();
      }
    },
  },

  mounted() {
    this.getCardProcessors();
  },

  methods: {
    ...mapActions("userInfo", ["getCreditQualification"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showAlert", "showToast"]),

    getCardProcessors() {
      GET_CARD_PROCESSERS()
        .then((res) => {
          this.options = res.data.data;
        })
        .catch(() => {});
    },

    addCard(processor) {
      if(processor) {
        this.loadProviders = true;
      }
      this.showCard = true;
      this.setLoading(true);
      ADD_CARD({
        walletId: this.walletId,
        redirectUrl: `${window.location.origin}/bare`,
        preferredProcessor: processor ? processor : null
      })
        .then((res) => {
          this.setLoading(false);
          this.source =
            res?.data?.data?.meta?.cardInfo?.serviceProviderPaymentLink;
          this.transactionId = res?.data?.data?.id;
          this.showCard = true;
        })
        .catch(() => {
          this.setLoading(false);
          this.showCard = false;
          this.$emit("stopAddCard");
        });
    },

    handleCardClose(e, data) {
      this.setLoading(false);
      this.showCard = false;
      this.$emit("stopAddCard");
      this.source = "";
      if (e === "successful") {
        this.showPaymentOptionsModal = false;
        this.showAlert({
          description: "Card successfully added",
          display: true,
          type: "success",
          buttonText: "Okay",
        });
        this.$emit("close", data);
      } else {
        this.showPaymentOptionsModal = true;
      }
    },

    loadIframe() {
      window.addEventListener("message", this.handleEvent, false);
      this.loadProviders = false;
      try {
        const location = this.$refs.iframeRef?.contentWindow?.location;
        // console.log(location.pathname);
        if (location?.pathname === "/bare") {
          // console.log("location", location);
          const params = new URL(location).searchParams;
          const status = params.get("status");
          if (status === "successful") {
            const txRef = params.get("tx_ref");
            this.verifyCard();
          } else {
            this.handleCardClose("aborted")
          }
        }
      } catch (e) {
        console.log("error handled in iframe");
      }
    },

    verifyCard() {
      return new Promise((resolve, reject) => {
        VERIFY_CARD({ transactionReference: this.transactionId })
          .then((res) => {
            this.handleCardClose("successful", res.data.data);
            // if zilla hasn't be activated
            !this.creditQualification && this.getCreditQualification();
            // console.log(res, "verified from backend");
            resolve(res);
          })
          .catch((err) => {
            this.handleCardClose("aborted");
            reject(err);
          });
      });
    },

    handleEvent(e) {
      switch (e.origin) {
        case "https://checkout.paystack.com":
          this.handlePaystack(e);
          break;
        case "https://ravemodal-dev.herokuapp.com":
          this.handleFlutterave(e);
          break;
      }
    },

    handlePaystack(e) {
      switch (e?.data?.event) {
        case "close":
          this.handleCardClose("aborted");
          break;
        case "success":
          this.verifyCard();
          break;
      }
      window.removeEventListener("message", this.handleEvents, false);
    },

    handleFlutterave(e) {
      switch (e?.data?.name) {
        case "closeiframe":
        this.handleCardClose("aborted");
          break;
        case "successful":
          this.verifyCard();
          break;
      }
      window.removeEventListener("message", this.handleEvents, false);
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.payment-space {
  height: 14rem;
  text-align: center;
}
.payment-space img {
  margin: auto;
}
.showCard {
  display: block;
}
</style>
